import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../../assets/images/meta/pull-panda-meta.png'
import AllWorkIcon from '../../assets/images/product/blurb-icons/all-work.png'
import GearIcon from '../../assets/images/product/blurb-icons/gear.png'
import LockIcon from '../../assets/images/product/blurb-icons/lock.png'
import FAQ from '../../components/EnterpriseFAQ'
import Layout from '../../components/Layout'
import CTABlock from '../../components/blocks/CTABlock'
import ColumnsBlock from '../../components/blocks/ColumnsBlock'
import { DarkLogoBlock } from '../../components/blocks/DarkLogoBlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import QuoteMosaicBlock from '../../components/blocks/QuoteMosaicBlock'
import { FeaturedBlogArticles } from '../../components/changelog/FeaturedBlogArticles'
import Box from '../../components/primitives/Box'
import Blurb from '../../components/system/Blurb'
import { responsiveScale } from '../../styles/helpers'
import QuoteBlock, { quotes } from '../../components/blocks/QuoteBlock'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/overview/hero-bg.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    mosaicRight: file(
      relativePath: { eq: "images/mosaic/slack-transparent.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft: file(relativePath: { eq: "images/mosaic/pr-view.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight2: file(
      relativePath: { eq: "images/mosaic/developer-experience.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    overview: file(relativePath: { eq: "images/enterprise/overview.png" }) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    integrations: file(
      relativePath: { eq: "images/enterprise/integrations.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    organizationalInsights: file(
      relativePath: {
        eq: "images/product/overview/organizational-insights.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 632)
      }
    }
    deploymentInsights: file(
      relativePath: { eq: "images/product/overview/deployment-insights.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 632)
      }
    }
    pinpointIssues: file(
      relativePath: { eq: "images/product/overview/pinpoint-issues.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
  }
`

const PullPandaPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Pull Panda vs. Swarmia"
      variant="dark"
      isNew
      description="Beyond offering more informative Slack notifications, and intuitive dashboards, Swarmia helps you drive developer productivity across the entire engineering organization."
      metaImage={MetaImage}
    >
      <HeroBlock
        title={<>Pull Panda vs. Swarmia</>}
        content="Pull Panda, now part of GitHub, was a pioneering tool for PRs and code reviews. However, many believe GitHub's native features don't fully meet modern engineering needs. For actionable Slack notifications and PR management, Swarmia is the right choice."
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <DarkLogoBlock />
      <LeadBlock
        heading="Why modern engineering organizations choose Swarmia over GitHub’s native tools"
        content="Beyond offering more informative Slack notifications, and intuitive dashboards, Swarmia helps you drive developer productivity across the entire engineering organization."
      />
      <QuoteMosaicBlock
        title="Faster Slack notifications"
        content={
          <>
            Say adios to jumping between apps. Swarmia’s two-way Slack
            notifications allow you to read, reply, and react to GitHub comments
            without leaving Slack. Unlike Pull Panda, Swarmia’s Slack messages
            give you all the relevant information at a glance.
          </>
        }
        image={getImage(data.mosaicRight)!}
        imageAlign="right"
        quote={quotes.josep}
      />
      <QuoteMosaicBlock
        title="Stay on top of your PRs"
        content={
          <>
            With GitHub, it is surprisingly difficult to see all open pull
            requests and where they are going in a team context. The Pull
            Request view in Swarmia works in real-time and helps teams to manage
            their pull requests in progress, make sure they are moving forward
            swiftly, and ensure no pull request is forgotten.
          </>
        }
        image={getImage(data.mosaicLeft)!}
        quote={quotes.svyat}
      />
      <QuoteMosaicBlock
        title="Go beyond the basics"
        content={
          <>
            While GitHub gives you a simplified overview of your engineering
            organization, Swarmia provides a holistic view, including metrics on
            developer productivity, investment balance, and CI/CD visibility.
          </>
        }
        image={getImage(data.mosaicRight2)!}
        imageAlign="right"
        quote={quotes.christian}
      />
      <QuoteBlock person="pau" />
      <CTABlock title={<>Get started with Swarmia today.</>} />
      <LeadBlock
        heading="Enterprise-grade features meet lightweight setup"
        content="Swarmia combines the best of both worlds: enterprise-grade features with flexible self-service configuration that only takes minutes."
      />
      <ColumnsBlock
        paddingTop={responsiveScale(48)}
        paddingBottom={responsiveScale(48)}
        columns={[
          <Blurb
            key="1"
            title="Built to scale"
            text="From team hierarchies to role-based access and flexible data exports, Swarmia fits the needs of large and growing organizations."
            isLarge
            image={getImage(data.overview)!}
          />,
          <Blurb
            key="2"
            title="Easy to integrate"
            text="Swarmia works with your internal systems as well as the the tools your engineering teams already use."
            isLarge
            image={getImage(data.integrations)!}
          />,
        ]}
      />
      <ColumnsBlock
        paddingBottom={responsiveScale(64)}
        columns={[
          <Blurb
            key="1"
            icon={GearIcon}
            title="Flexible set up and configuration"
            text="Setting up Swarmia is easy. You can either do it yourself or get support from our customer success team — at no extra cost."
          />,
          <Blurb
            key="2"
            icon={AllWorkIcon}
            title="Roll out at your own pace"
            text="Your dedicated customer success manager will work with your team to tailor onboarding and roll out."
          />,
          <Blurb
            key="3"
            icon={LockIcon}
            title="Designed security-first"
            text="Swarmia is SOC 2 Type 2 and GDPR compliant. We perform thorough security audits twice a year."
          />,
        ]}
      />
      <Box
        // This is needed for the FAQ styles to work
        className="main-body-old-styles"
      >
        <FAQ />
      </Box>
      <Box paddingBottom={responsiveScale(64)}>
        <CTABlock title={<>Get started with Swarmia today.</>} />
        <FeaturedBlogArticles
          heading="More from the swarmia blog"
          articles={[
            '/blog/size-age-culture-productivity/',
            '/blog/measuring-software-development-productivity/',
            '/blog/balancing-engineering-investments/',
          ]}
          newStyles
        />
      </Box>
    </Layout>
  )
}

export default PullPandaPage
